@import '../../styles/breakpoints';
@import '../../styles/colors';
@import '../../styles/dimensions';
@import '../../styles/font-sizes';
@import '../../styles/utils';

$icon-size: 18px;
@include scroll-anchor;

.details {
    border-top: 1px solid var(--step-right-before-midnight);
    outline: none;
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-bottom: space(2);
    margin-top: space(2);
}

.text {
    display: block;
    padding: 0 0 $gap-small;
}

.summary {
    @include text-menu-header;
    background: inherit;
    cursor: pointer;
    font-weight: 500;
    outline: none;
}

.summary-content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: $gap-medium 0;
    text-align: left;
}

.summary-text-loading {
    width: 100%;
}

.summary-text {
    padding-right: $icon-size * 2;
    width: fit-content;
}

.summary-icon {
    flex: 0 0 $icon-size;
    text-align: right;

    svg {
        color: var(--theme-primary);
        display: flex;
        height: $icon-size;
        width: $icon-size;
    }
}

.summary::-webkit-details-marker {
    display: none;
}

.summary::marker {
    content: '';
    display: none;
}
