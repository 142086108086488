@import '../../styles/breakpoints';
@import '../../styles/dimensions';

$icon-size: 18px;

.section {
    margin-bottom: $gap-x-large;
    margin-top: $gap-x-large;
    max-width: 100%;

    @include screen-min-width('md') {
        @media screen and (orientation: landscape) {
            box-sizing: content-box;
            margin-bottom: $gap-x-large * 2;
            margin-top: $gap-x-large * 2;
            max-width: $heading-max-width-medium;
        }
    }
}

.heading {
    text-align: center;
}

.item .paragraph {
    padding-bottom: 0;
}

.hr {
    border: unset;
    margin: 0;
    padding: $gap-small 0 0;
}

.header {
    margin-bottom: space(20);
    text-align: center;

    @include screen-max-width('md') {
        margin-bottom: space(12);
    }
}

.external-link {
    align-items: center;
    border-top: 1px solid var(--step-right-before-midnight);
    color: var(--theme-primary);
    display: grid;
    grid-column-gap: space(10);
    grid-template-columns: auto $icon-size;
    overflow: hidden;
    padding: 24px 0;

    svg {
        color: var(--theme-primary);
        display: flex;
        height: $icon-size;
        width: $icon-size;
    }

    &:hover {
        cursor: pointer;
    }
}
